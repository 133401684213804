import React from 'react';
import 'moment-timezone';
import {Route, Switch} from 'react-router-dom'
import Order from "./sections/order/Order";
import List from "./sections/list/List";
import Resume from "./sections/resume/Resume";
import Detail from "./sections/detail/Detail";
import Home from "./sections/home/Home";
import Profile from "./sections/profile/Profile";
import {PrivateRoute} from "./utils/PrivateRoute";

function App() {
    return (
        <Switch>
            <Route exact path="/" component={Home}/>
            <PrivateRoute exact path="/order/:id?" component={Order}/>
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/list" component={List}/>
            <PrivateRoute exact path="/details/:id" component={Detail}/>
            <PrivateRoute exact path="/result/" component={Resume}/>
        </Switch>
    );
}

export default App;
