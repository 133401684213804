import {createSlice} from "@reduxjs/toolkit";
import toast from 'react-hot-toast';
import _ from "lodash";
import {callGet, callPost, errorCall} from "../../utils/call";

export const orderSlice = createSlice({
        name: 'Order',
        initialState: {
            name: localStorage.getItem('sl_name'),
            order: [{number: null, qty: '1'}, {number: null, qty: '1'}, {number: null, qty: '1'}, { number: null, qty: "1"}, {number: null, qty: '1'}],
            note: '',
            saved:false,
            save_name: '',
            disabled: false,
            saved_list: []
        },
        reducers: {
            setName: (state, action) => {
                state.name = action.payload
            },
            setSingleOrder: (state, action) => {
                state.order[action.payload.pos][action.payload.key] = action.payload.value
            },
            setOrder: (state, action) => {
                state.order = action.payload
            },
            setNote: (state, action) => {
                state.note = action.payload
            },
            setSave: (state, action) => {
                state.saved = action.payload
            },
            setSaveName: (state, action) => {
                state.save_name = action.payload
            },
            setSavedList: (state, action) => {
                state.saved_list = action.payload
            }
        },
    })
export function getDetail(id) {
    return async dispatch => {
        try {

            const response = await callGet(`order/detail/${id}`, true)

            console.log(response.data.save_name)
            if (response.status === 200) {
                dispatch(setOrder(response.data.order))
                dispatch(setNote(response.data.note))
                dispatch(setSave(response.data.saved))
                dispatch(setSaveName(response.data.save_name))
            }

        } catch (error) {
            errorCall(error)
        }
    }
}
export function getOrderSaved(handleDialog){
    return async dispatch => {
        try {
            const dinner = JSON.parse(localStorage.getItem('sl_dinner'))
            const response = await callGet(`order/saved/list/${dinner.id}`, true)
            dispatch(setSavedList(response.data.saved_list))
            if(response.data.saved_list.length !== 0){
                handleDialog()
            }

        } catch (error) {
            errorCall(error)
        }
    }
}
export async function submit(state) {

    try{
        if (_.isEmpty(state.name) || state.name.trim().length < 2) {
            toast.error('Perchè non hai inserito il tuo nome?')
            return false
        }
        let error = 0;
        state.order.forEach((elm, key) => {
            if ((elm.number !== null && elm.qty === null) || (elm.number === null && elm.qty !== null)) error++
        })
        if (error > 0) {
            toast.error('Uhm... mi sa che hai dimenticato la quantita o il numero di qualche porzione')
            return false
        }
        const data = {...state}
        const dinner = JSON.parse(localStorage.getItem('sl_dinner'));
        data.dinner_id = dinner.id
        delete data.disabled
        data.id = JSON.parse(localStorage.getItem('sl_dinner')).order

        const response = await callPost(`order/save`, true, data)

        if (response.status === 200) {
            toast.success('Ok, tutto è stato salvato correttamente')
            return response.data.id

        } else {
            toast.error('Uhm... mi sa che c\'è qualche bug... va bhe facciamo alla vecchia maniera')
            return false
        }
    } catch (error) {
        errorCall(error)
    }


}

export const {setName, setSingleOrder, setOrder, setNote,setSave,setSaveName, setSavedList} = orderSlice.actions;
export const selectOrder = state => state.order;

export default orderSlice.reducer;
