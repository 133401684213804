import {callGet, callPost, errorCall} from "../../utils/call";
import toast from "react-hot-toast";
import {isEmpty} from "lodash";

export async function getMe() {
    try {
        const response = await callGet(`user/me`, true)
        return response.data
    } catch (error) {
        errorCall(error)
    }
}

export async function updateData(data) {
    try {
        let formData = new FormData();
        Object.entries(data).forEach((elm) =>{
            if(!isEmpty(elm[1]) || elm[0] === 'file') {
                formData.append(elm[0], elm[1]);
            }
        });
        const response = await callPost(`user/update`, true, formData,'multipart/form-data')
        toast.success(response.data.msg)
        localStorage.setItem('sl_name', data.name)
    } catch (error) {
        errorCall(error)
    }
}

export async function updateAlert(data) {
    try {
        const response = await callGet(`beta/baualert/${(data) ? 'enable' : 'disable'}`, true)
        toast.success(response.data.msg)
        localStorage.setItem('sl_name', data.name)
    } catch (error) {
        errorCall(error)
    }
}
