import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {ClickAwayListener, ListItemIcon, Typography} from "@mui/material";
import {Home, Toc, Gavel, PersonOutline, ExitToApp} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import {GoogleLogout} from "react-google-login";
import {callGet} from "../utils/call";
import toast from "react-hot-toast";
import _ from "lodash";


export default function LongMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const history = useHistory();
    const options = [
        {label: 'Home', url: '/', icon: Home},
        {label: 'Il tuo profilo', url: '/profile', icon: PersonOutline}
    ];
    if (!_.isEmpty(localStorage.getItem('sl_dinner'))){
        options.push(
            {label: 'Lista ordini', url: '/list', icon: Toc},
            {label: 'Riassunto ordinazione', url: '/result', icon: Gavel}
        )
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (url) => {
        history.push(url)
        setAnchorEl(null)
    };
    const handleCloseZone = () => {
        setAnchorEl(null)
    }

    const logoutCall = async () => {
        await callGet('auth/logout', true)
        localStorage.clear()
        toast.success('Logout effettuato');
        setTimeout(() => {
            window.location.href = '/'
        }, 3000)
    }

    return (
        <ClickAwayListener onClickAway={handleCloseZone}>
            <div>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls="long-menu"
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {options.map((option, key) => (
                        <MenuItem key={key} onClick={() => {
                            handleClose(option.url)
                        }} href={option.url}>
                            <ListItemIcon>
                                <option.icon fontSize="small"/>
                            </ListItemIcon>
                            <Typography variant="inherit">{option.label}</Typography>
                        </MenuItem>
                    ))}
                    {localStorage.getItem('sl_google') &&
                        <GoogleLogout
                            clientId={process.env.REACT_APP_CLIENT_GOOGLE}
                            onLogoutSuccess={logoutCall}
                            render={renderprops => (
                                <MenuItem key={'google_logout'} onClick={renderprops.onClick} href='#'>
                                    <ListItemIcon>
                                        <ExitToApp fontSize="small"/>
                                    </ListItemIcon>
                                    <Typography variant="inherit">Logout</Typography>
                                </MenuItem>
                            )}
                        />

                    }
                    {!localStorage.getItem('sl_google') &&
                        <MenuItem key={'logout'} onClick={logoutCall} href='#'>
                            <ListItemIcon>
                                <ExitToApp fontSize="small"/>
                            </ListItemIcon>
                            <Typography variant="inherit">Logout</Typography>
                        </MenuItem>
                    }
                </Menu>
            </div>
        </ClickAwayListener>
    );
}
