import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    TextField
} from "@mui/material";
import {Send} from "@mui/icons-material";
import React, {useEffect} from "react";
import {callPost, errorCall} from "../../../utils/call";
import toast from "react-hot-toast";
import {isEmpty} from "lodash";
import {getBeta} from "../../../utils/beta";

export default function Login(props) {
    const [user, setUser] = React.useState({email: '', password: ''})
    const [passwordG, setPasswordG] = React.useState({password: '', password_repeat: ''})
    const [restore, setRestore] = React.useState('')
    const [open, setOpen] = React.useState(false)

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get('token');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const redirectSave = (response) => {
        toast.success(response.data.msg)
        localStorage.setItem('sl_id', response.data.data.id)
        localStorage.setItem('sl_name', response.data.data.name)
        localStorage.setItem('sl_isLogged', 1)
        window.location.href = '/';
    }
    const saveUser = async () => {
        try {
            const bauFurto = getBeta('baudog')
            const response = await callPost(`auth/login`, true, user)
            if (bauFurto) props.bauEvent()
            setTimeout(() => {
                redirectSave(response)
            }, (bauFurto) ? 6000 : 1000)
        } catch (error) {
            errorCall(error)
        }
    }

    const resetUser = async () => {
        try {
            const response = await callPost(`auth/reset/password`, true, {email: restore})
            toast.success(response.data.msg)
        } catch (error) {
            errorCall(error)
        }
    }

    const saveResetPassword = async () => {
        try {
            const response = await callPost(`auth/reset/password/save`, true, {
                password: passwordG.password,
                password_repeat: passwordG.password_repeat,
                token
            })
            redirectSave(response)
        } catch (error) {
            errorCall(error)
        }
    }
    useEffect(() => {
        if (!isEmpty(token)) {
            handleClickOpen()
        }

        // eslint-disable-next-line
    }, [])
    return (
        <>
            <Grid container spacing={2} style={{marginTop: '20px'}}>

                <Grid item xs={12}>
                    <TextField
                        required
                        id="email"
                        label="Email"
                        name={'email'}
                        style={{background: '#fff', width: '100%'}}
                        onBlur={(e) => {
                            const usr = {...user}
                            usr.email = e.target.value;
                            setUser(usr)
                        }
                        }
                    />

                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="password"
                        label="Password"
                        name={'password'}
                        type={'password'}
                        style={{background: '#fff', width: '100%'}}
                        onBlur={(e) => {
                            const usr = {...user}
                            usr.password = e.target.value;
                            setUser(usr)
                        }
                        }
                    />

                </Grid>
                <Grid item xs={12} textAlign={'right'}>
                    <Link color={'primary'} style={{paddingTop: '20px', cursor: 'pointer'}} onClick={() => {
                        handleClickOpen()
                    }}>Hai dimenticato la
                        password?</Link>
                </Grid>
                <Grid item xs={12} textAlign={'center'}>
                    <Button variant="contained" endIcon={<Send/>} style={{width: '100%'}} onClick={() => {
                        saveUser()
                    }}>
                        ACCEDI
                    </Button>
                    <br/> <br/>
                    <Link color={'primary'} style={{paddingTop: '20px', cursor: 'pointer'}} onClick={() => {
                        props.event(true)
                    }}>Non hai ancora un account?</Link>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Recupera password"}</DialogTitle>
                <DialogContent>
                    {isEmpty(token) &&
                        <Grid container spacing={2} style={{marginTop: '10px'}}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="email_restore"
                                    label="Email"
                                    name={'email_restore'}
                                    style={{background: '#fff', width: '100%'}}
                                    onBlur={(e) => {
                                        setRestore(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" endIcon={<Send/>} style={{width: '100%'}} onClick={() => {
                                    resetUser()
                                }}>
                                    INVIA
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {!isEmpty(token) &&
                        <Grid container spacing={2} style={{marginTop: '10px'}}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    type={'password'}
                                    id="password_new"
                                    label="Password"
                                    name={'password_new'}
                                    style={{background: '#fff', width: '100%'}}
                                    onBlur={(e) => {
                                        const pswN = {...passwordG}
                                        pswN.password = e.target.value
                                        setPasswordG(pswN)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    type={'password'}
                                    id="password_new_repeat"
                                    label="Ripeti password"
                                    name={'password_new_repeat'}
                                    style={{background: '#fff', width: '100%'}}
                                    onBlur={(e) => {
                                        const pswN = {...passwordG}
                                        pswN.password_repeat = e.target.value
                                        setPasswordG(pswN)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" endIcon={<Send/>} style={{width: '100%'}} onClick={() => {
                                    saveResetPassword()
                                }}>
                                    SALVA
                                </Button>
                            </Grid>
                        </Grid>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Chiudi</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
