import React, {useEffect} from 'react';
import {
    Button,
    Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel,
    Grid, Paper, Switch,
    TextField,
    Typography
} from "@mui/material";
import DeleteForever from '@mui/icons-material/DeleteForever';
import {
    setName,
    setSingleOrder,
    selectOrder,
    setOrder,
    submit,
    setNote,
    setSave,
    setSaveName,
    getDetail,
    getOrderSaved
} from "./OrderSlice";
import {useDispatch, useSelector} from "react-redux";
import Add from '@mui/icons-material/Add';
import Send from '@mui/icons-material/Send';
import {v4 as uuid4} from 'uuid';
import {Toaster} from 'react-hot-toast';
import {useHistory, useParams} from "react-router-dom";
import Headers from "../../components/Headers";
import {getDinner, selectDinner} from "../dinner/DinnerSlice";
import {isEmpty} from "lodash";

export default function Order() {
    const state = useSelector(selectOrder)
    const stateDinner = useSelector(selectDinner)
    const dispatch = useDispatch()
    const history = useHistory()
    const [open, setOpen] = React.useState(false)
    const id = useParams().id

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const addElm = () => {
        const orderObj = [...state.order]
        orderObj.push({number: null, qty: '1'})
        dispatch(setOrder(orderObj))
    }
    const delElm = (key) => {
        const orderObj = [...state.order]
        orderObj.splice(key, 1)
        dispatch(setOrder(orderObj))
    }
    useEffect(() => {
        dispatch(getDinner())

        if (!isEmpty(id)) {
            dispatch(getDetail(id))
        } else {
            dispatch(getOrderSaved(handleClickOpen))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <Toaster/>
        <Container maxWidth="sm">
            <Grid container spacing={2}>
                <Grid item xs={12} textAlign={'center'} style={{position: 'relative'}}>
                    <Headers title={'Ordiniamo!'} showMenu={true}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Come ti chiami?"
                        style={{background: '#fff', width: '100%'}}
                        onBlur={(e) => {
                            dispatch(setName(e.target.value));
                        }}
                        defaultValue={state.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="button" gutterBottom component="div">Cosa mangi?</Typography>
                </Grid>
                {
                    state.order.map((elm, k) => {
                        return (<React.Fragment key={uuid4()}>
                            <Grid item xs={5}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Numero"
                                    style={{background: '#fff'}}
                                    type="text"
                                    defaultValue={elm.number}
                                    inputProps={{pattern:'[0-9]{1,4}[a-zA-Z]?'}}
                                    onBlur={(e) => {
                                        dispatch(setSingleOrder({pos: k, key: 'number', value: e.target.value}))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Qty"
                                    style={{background: '#fff'}}
                                    type="number"
                                    defaultValue={elm.qty}
                                    onBlur={(e) => {
                                        dispatch(setSingleOrder({pos: k, key: 'qty', value: e.target.value}))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} textAlign={'center'}>
                                <DeleteForever style={{fontSize: '30px', marginTop: '10px'}} onClick={() => {
                                    delElm(k)
                                }}/>
                            </Grid>
                        </React.Fragment>)
                    })}


                <Grid item xs={12} textAlign={'right'}>
                    <Button variant="outlined" startIcon={<Add/>} onClick={addElm}>
                        AGGIUNGI
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-flexible"
                        label="Note"
                        multiline
                        maxRows={4}
                        key={uuid4()}
                        style={{background: '#fff', width: '100%'}}
                        onBlur={(e) => {
                            dispatch(setNote(e.target.value));
                        }}
                        defaultValue={state.note}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Switch checked={state.saved} onChange={(e) => {
                        dispatch(setSave(e.target.checked))
                        if (e.target.checked && isEmpty(state.save_name)) {
                            dispatch(setSaveName(`${stateDinner.dinner.restaurant} - ${stateDinner.dinner.created_at}`))
                        }
                    }}
                    />} label="Salva l'ordine per utilizzarlo in futuro"/>
                </Grid>
                {state.saved &&
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Nome salvataggio"
                            key={uuid4()}
                            style={{background: '#fff', width: '100%'}}
                            defaultValue={state.save_name}
                            onBlur={(e) => {
                                dispatch(setSaveName(e.target.value));
                            }}

                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Button variant="contained" color="success" startIcon={<Send/>}
                            style={{width: '100%', marginTop: '40px', height: '30px'}}
                            onClick={async () => {
                                const id = await submit(state)
                                if (id) {

                                    let dinner = JSON.parse(localStorage.getItem('sl_dinner'))
                                    dinner.order = id
                                    localStorage.setItem('sl_dinner', JSON.stringify(dinner))
                                    history.push('/')
                                }
                            }}>
                        INVIA
                    </Button>
                </Grid>

            </Grid>
        </Container>
        <Dialog
            open={open}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Vuoi utilizzare uno dei tuoi precedenti salvataggi?"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {state.saved_list.map((elm) => {
                        return (
                            <React.Fragment key={uuid4()}>
                                <Grid item xs={12}>
                                    <Paper variant="outlined" style={{background: '#fdc', position: 'relative'}}>
                                        <div style={{padding: '10px', margin: '0px'}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <strong>{elm.save_name}</strong>
                                                    <Typography variant="overline" display="block" gutterBottom
                                                                component="div">Numero di
                                                        piatti: {elm.order.length}</Typography>
                                                </Grid>
                                                <Grid item xs={12} style={{textAlign:'center'}}>
                                                    <Button variant="contained"
                                                            style={{marginTop: '10px', marginLeft: '10px'}}
                                                            onClick={() => {
                                                                dispatch(setOrder(elm.order))
                                                                dispatch(setNote(elm.note))
                                                                handleClose()
                                                            }}>Usa</Button>
                                                </Grid>
                                            </Grid>
                                        </div>

                                    </Paper>
                                </Grid>
                            </React.Fragment>)
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Chiudi</Button>
            </DialogActions>
        </Dialog>
    </>)
}
