import {createSlice} from "@reduxjs/toolkit";
import {apiUrl, callGet, errorCall} from "../../utils/call";
import {isEmpty} from "lodash";

export const detailSlice = createSlice({
    name: 'detail',
    initialState: {
        name: '',
        orders: [],
        notes: '',
        image: ''
    },
    reducers: {
        setOrders: (state, action) => {
            state.orders = action.payload
        },
        setNotes: (state, action) => {
            state.notes = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setImage: (state, action) => {
            state.image = action.payload
        }
    },
})

export function getDetail(id) {
    return async dispatch => {
        try {
            const beta = !isEmpty(localStorage.getItem('sl_pietanze_name'))? '?beta=true' : ''
            const response = await callGet(`order/detail/${id}${beta}`, true)

            if (response.status === 200) {
                dispatch(setOrders(response.data.order))
                dispatch(setNotes(response.data.note))
                dispatch(setName(response.data.name))
                if(response.data.user.image) {
                    dispatch(setImage(apiUrl(response.data.user.image)))
                }else{
                    dispatch(setImage(null))
                }

            }

        } catch (error) {
            errorCall(error)
        }
    }
}
export const {setOrders,setName,setNotes,setImage} = detailSlice.actions;
export const selectDetail = state => state.detail;

export default detailSlice.reducer;
