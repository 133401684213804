import React, {useEffect} from "react";
import {getCode, getRestaurants, saveDinner, selectDashboard} from "../DashboardSlice";
import {useDispatch, useSelector} from "react-redux";
import {Button, FormControl, Grid, InputLabel, Select, TextField, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {v4 as uuid4} from "uuid";
import {Cached, Send} from "@mui/icons-material";

export default function Create() {
    const [newDinner, setNewDinner] = React.useState({restaurant: '', code: '', other: '',name:''});
    const state = useSelector(selectDashboard);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getRestaurants())
        const codeName = async () => {
            const dinner = {...newDinner}
            const codeDynamic = await getCode()
            dinner.code = codeDynamic
            setNewDinner(dinner);

        }
        codeName()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const refreshCode = async () => {
        const dinner = {...newDinner}
        const codeDynamic = await getCode()
        dinner.code = codeDynamic
        setNewDinner(dinner);
    }

    const save = () => {
        dispatch(saveDinner(newDinner))
    }

    return (<>
        <Grid item xs={12}>
            <Typography variant="button" gutterBottom component="div">Crea una cena</Typography>
        </Grid>
        <Grid item xs={12}>
            <TextField
                required
                id="nome_cena"
                label="Nome"
                name={'nome'}
                style={{width: '100%'}}
                defaultValue={newDinner.name}
                onBlur={(e) => {
                    const dinner = {...newDinner}
                    dinner.name = e.target.value
                    setNewDinner(dinner);
                }}
            />
        </Grid>
        <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Ristorante</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Ristorante"
                    value={newDinner.restaurant}
                    style={{background: '#fff', width: '100%'}}
                    onChange={(e) => {
                        const dinner = {...newDinner}
                        dinner.restaurant = e.target.value
                        setNewDinner(dinner);
                    }}
                >
                    {state.restaurants.map((elm) => {
                        return (<MenuItem value={elm} key={uuid4()}>{elm.name}</MenuItem>)
                    })}
                    <MenuItem value={'other'} key={uuid4()}>Altro</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        {
            newDinner.restaurant === 'other' &&
            <Grid item xs={12}>
                <TextField
                    required
                    id="ristorante"
                    label="ristorante"
                    name={'ristorante'}
                    style={{width: '100%'}}
                    defaultValue={newDinner.other}
                    onBlur={(e) => {
                        const dinner = {...newDinner}
                        dinner.other = e.target.value
                        setNewDinner(dinner);
                    }}
                />
            </Grid>
        }
        <Grid item xs={10}>
            <TextField
                required
                disabled={true}
                id="codice_new"
                label="Codice"
                name={'codice'}
                style={{width: '100%'}}
                helperText="Il codice viene generato automaticamente e ti servirà per fare accedere i tuoi amici  alla  cena"
                value={newDinner.code}
            />
        </Grid>
        <Grid item xs={2} textAlign={'left'}>
            <Cached style={{fontSize: '36px', paddingTop: '10px', cursor: 'pointer'}} onClick={() => {
                refreshCode()
            }}/>
        </Grid>
        <Grid item xs={12} textAlign={'center'}>
            <Button variant="contained" endIcon={<Send/>} style={{width: '100%'}} color="success" onClick={() => {
                save()
            }}>
                Crea
            </Button>
        </Grid>
    </>)
}
