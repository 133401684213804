import {Toaster} from "react-hot-toast";
import React, {useEffect} from "react";
import {Avatar, Button, Container, Divider, FormControlLabel, Grid, Switch, TextField, Typography} from "@mui/material";
import Headers from "../../components/Headers";
import {getMe, updateAlert, updateData} from "./ProfileSlice";
import {isEmpty} from "lodash";
import {v4 as uuid4} from 'uuid';
import {apiUrl} from "../../utils/call";

export default function Profile() {
    const [showSignIn, setShowSignIn] = React.useState({name: null, email: null, google_id: '--',password: null, password_repeat: null,role:null,file:''});
    const [switchName, setSwitchName] = React.useState(false)
    const [switchBau, setSwitchBau] = React.useState(false)
    const [previewImage, setPreview] = React.useState({type: 'image', file: ''});
    const previewFile = (elm) => {
        try {
          setPreview({type: 'image', file: window.URL.createObjectURL(elm.target.files[0])});

            const data = {...showSignIn}
            data.file = elm.target.files[0]
            setShowSignIn(data)
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        const meInfo = async () => {
            const me = await getMe()
            if(!isEmpty(me.image)){

                setPreview({type: 'image', file: apiUrl(me.image)});
                delete me.image
            }
            setShowSignIn({...me, password: null, password_repeat: null})
        }
        meInfo();
        if(!isEmpty(localStorage.getItem('sl_pietanze_name'))){
            setSwitchName(true)
        }
    }, [])

    return (<>
            <Toaster/>
            <Container maxWidth="sm">
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign={'center'} style={{position: 'relative'}}>
                        <Headers title={'Il tuo profilo'} showMenu={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Container maxWidth="sm">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="button" gutterBottom component="div">Profilo</Typography>
                                </Grid>
                                <Grid item xs={12} style={{height:'90px',width:'90px',position:'relative',marginBottom:'20px'}}>
                                    <label htmlFor="contained-button-file" style={{cursor:'pointer',position:'absolute',left:'50%',transform:'translateX(-50%)', width:'90px',height:'90px'}}>
                                    <Avatar style={{width:90, height:90}}  src={previewImage.file || ''}/>
                                    </label>
                                    <input
                                        accept={'image/*'}
                                        id="contained-button-file"
                                        type="file"
                                        hidden={true}
                                        onChange={(e) => {
                                            previewFile(e);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="nome"
                                        label="Nome"
                                        name={'nome'}
                                        style={{width: '100%'}}
                                        key={uuid4()}
                                        defaultValue={showSignIn.name}
                                        onBlur={(e) => {
                                            const data = {...showSignIn}
                                            data.name = e.target.value
                                            setShowSignIn(data)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="email"
                                        label="Email"
                                        name={'email'}
                                        style={{width: '100%'}}
                                        key={uuid4()}
                                        defaultValue={showSignIn.email}
                                        onBlur={(e) => {
                                            const data = {...showSignIn}
                                            data.email = e.target.value
                                            setShowSignIn(data)
                                        }}
                                    />
                                </Grid>
                                {isEmpty(showSignIn.google_id) &&
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                type={'password'}
                                                id="password"
                                                label="Password"
                                                name={'password'}
                                                style={{width: '100%'}}
                                                onBlur={(e) => {
                                                    const data = {...showSignIn}
                                                    data.password = e.target.value
                                                    setShowSignIn(data)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                type={'password'}
                                                id="password_repeat"
                                                label="Ripeti password"
                                                name={'password_repeat'}
                                                style={{width: '100%'}}
                                                onBlur={(e) => {
                                                    const data = {...showSignIn}
                                                    data.password_repeat = e.target.value
                                                    setShowSignIn(data)
                                                }}
                                            />
                                        </Grid>
                                    </>

                                }
                                <Grid item xs={12} textAlign={'right'}>
                                    <Button variant="contained" size="large" onClick={() => {
                                        updateData(showSignIn)
                                    }}>
                                        SALVA
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="button" gutterBottom component="div" style={{textTransform:'uppercase'}}>Attiva funzionalità beta</Typography>
                                </Grid>

                                { !isEmpty(showSignIn.role) && showSignIn.role === 'admin' &&
                                    <>
                                        <Grid item xs={12}>
                                            <FormControlLabel control={<Switch checked={switchBau} onChange={(e) => {
                                                if(e.target.checked){
                                                    setSwitchBau(true)
                                                }else{
                                                    setSwitchBau(false)
                                                }
                                                updateAlert(e.target.checked)
                                            }}  />} label="Attiva BauAlert" />
                                        </Grid>
                                    </>

                                }
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>

                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
