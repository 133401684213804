import {Button, Grid, Link, TextField} from "@mui/material";
import {Check} from "@mui/icons-material";
import React from "react";
import {callPost} from "../../../utils/call";
import toast from "react-hot-toast";
import {isEmpty} from "lodash";
import {getBeta} from "../../../utils/beta";

export default function SignIn(props) {
    const [user, setUser] = React.useState({name: '', email: '', password: ''});
    const saveUser = async () => {
        try {
            const bauFurto = getBeta('baudog')
            const response = await callPost(`auth/add`, true, user)
            toast.success(response.data.msg + 'Ora verrai reindirizzato')
            localStorage.setItem('sl_id', response.data.data.id)
            localStorage.setItem('sl_name', response.data.data.name)
            localStorage.setItem('sl_isLogged', 1)
            if(bauFurto) props.bauEvent()
            setTimeout(()=>{window.location.href='/';},(bauFurto)? 6000 : 1000)

        }catch(error){
            if(!isEmpty(error.response.data.msg)) {
                toast.error(error.response.data.msg)
            }else{
                toast.error('Qualcosa è andato storto riprova più tardi')
            }
        }
    }
    return (
        <Grid container spacing={2} style={{marginTop: '20px'}}>
            <Grid item xs={12}>
                <TextField
                    required
                    id="nome"
                    label="Nome"
                    name={'nome'}
                    style={{background: '#fff', width: '100%'}}
                    onBlur={(e) => {
                        const usr = {...user}
                        usr.name = e.target.value;
                        setUser(usr)
                    }
                    }
                />

            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    id="email"
                    label="Email"
                    name={'email'}
                    style={{background: '#fff', width: '100%'}}
                    onBlur={(e) => {
                        const usr = {...user}
                        usr.email = e.target.value;
                        setUser(usr)
                    }
                    }
                />

            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    id="password"
                    label="Password"
                    name={'password'}
                    type={'password'}
                    style={{background: '#fff', width: '100%'}}
                    onBlur={(e) => {
                        const usr = {...user}
                        usr.password = e.target.value;
                        setUser(usr)
                    }
                    }
                />
            </Grid>
            <Grid item xs={12} textAlign={'center'}>
                <Button variant="contained" color="success" startIcon={<Check/>} style={{width: '100%'}} onClick={() => {saveUser()}}>
                    REGISTRATI
                </Button>
                <br/> <br/>
                <Link color={'primary'} style={{paddingTop: '20px', cursor: 'pointer'}} onClick={() => {
                    props.event(false)
                }}>Hai già un account?</Link>
            </Grid>
        </Grid>

    )
}
