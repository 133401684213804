import {useDispatch} from "react-redux";
import {Button, Checkbox, Divider, FormControlLabel, Grid, Paper, Typography} from "@mui/material";
import React, {useState} from "react";
import {v4 as uuid4} from 'uuid';
import {useHistory} from "react-router-dom";
import {setNote, setOrder} from "../../order/OrderSlice";
import {remove,isEmpty} from "lodash";

export default function OrderList(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [received,setReceived] = useState([])

    return (<>
        <Grid item xs={12}>
            <Paper variant="outlined" style={{background: '#fff'}}>
                <Grid container spacing={2} style={{padding: '20px'}}>
                    <Grid item xs={12}>
                        <Typography variant="button" gutterBottom component="div">{(props.type === 'single')? 'Il tuo ordine' : 'Recap'}</Typography>
                    </Grid>
                    {props.data.order.map((elm) => {
                        const style = (received.includes(elm.number))? {textDecoration:'line-through'} : {}
                        if(props.type !== 'all') {
                            return (
                                <React.Fragment key={uuid4()}>
                                    <Grid item xs={12} style={{paddingTop: '0px'}}>
                                        <FormControlLabel control={<Checkbox checked={(received.includes(elm.number))}
                                                                             onChange={(e) => {
                                                                                 const data = [...received]
                                                                                 if (e.target.checked) {

                                                                                     data.push(elm.number)
                                                                                     setReceived(data)
                                                                                 } else {
                                                                                     remove(data, function (elmd) {
                                                                                         return elmd === elm.number
                                                                                     });
                                                                                     setReceived(data)
                                                                                 }
                                                                             }}/>} label={`${elm.number} ${(!isEmpty(elm.label)? '| '+elm.label : '')} x ${elm.qty}`}
                                                          style={style}/>
                                    </Grid>
                                </React.Fragment>
                            )
                        }else{
                            return (
                                <React.Fragment key={uuid4()}>
                                    <Grid item xs={12} style={{paddingTop: '0px'}}>

                                        <Typography variant="h6" component="div" style={{paddingTop:'10px'}}>
                                            {`${elm.number} ${(!isEmpty(elm.label)? '| '+elm.label : '')} x ${elm.qty}`}
                                        </Typography>
                                        <Divider/>
                                    </Grid>
                                </React.Fragment>
                            )
                        }
                    })}
                    {!isEmpty(props.data.note) &&
                        <>
                            <Grid item xs={12}>
                                <Typography variant="button" gutterBottom component="div">Note</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {props.data.note}
                            </Grid>
                        </>
                    }
                </Grid>
            </Paper>
        </Grid>
        {props.type === 'single' &&
            <Grid item xs={12}>
                <Button variant="contained" size="large" style={{width: '100%'}} onClick={() => {
                    dispatch(setOrder(props.data.order))
                    dispatch(setNote(props.data.note))
                    const dinner = JSON.parse(localStorage.getItem('sl_dinner'))
                    history.push(`/order/${dinner.order}`)
                }} color={'warning'}>
                    MODIFICA ORDINE
                </Button>

            </Grid>
        }
    </>)
}
