import {createSlice} from "@reduxjs/toolkit";
import {callGet, callPost, errorCall} from "../../utils/call";
import toast from "react-hot-toast";
import {isEmpty} from "lodash";


export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        restaurants: [],
        dinners: [],
        loaded: false
    },
    reducers: {
        setRestaurants: (state, action) => {
            state.restaurants = action.payload
        },
        setDinners: (state, action) => {
            state.dinners = action.payload
            state.loaded = true
        }
    },
})

export function getRestaurants() {
    return async dispatch => {
        try {

            const response = await callGet(`restaurant/list`, true)

            if (response.status === 200) {
                dispatch(setRestaurants(response.data.restaurant))
            }
        } catch (error) {
            errorCall(error)
        }
    }
}

export function getDinner() {
    return async dispatch => {
        try {
            const response = await callGet(`dinner/list`, true)
            if (response.status === 200) {

                if (response.data.length && isEmpty(localStorage.getItem('sl_dinner'))) localStorage.setItem('sl_dinner', JSON.stringify(response.data[0]))
                if (response.data.length === 0) localStorage.removeItem('sl_dinner')
                dispatch(setDinners(response.data))

            }
        } catch (error) {
            errorCall(error)
        }
    }
}

export async function getCode() {
    try {
        const response = await callGet(`dinner/code`, true)
        return response.data.code
    } catch (error) {
        errorCall(error)
    }
}

export function closeDinner(id,update = true) {
    return async dispatch => {
        try {
            const response = await callPost(`dinner/delete`, true, {id})
            if (response.status === 200 && update) {
                dispatch(getDinner())
                localStorage.removeItem('sl_dinner')
            }
        } catch (error) {
            errorCall(error)
        }
    }
}

export function saveDinner(data) {
    return async dispatch => {
        try {
            const response = await callPost(`dinner/save`, true, data)
            if (response.status === 200) {
                toast.success(response.data.msg)
                localStorage.setItem('sl_dinner', JSON.stringify(response.data.data))
                dispatch(getDinner())
            }
        } catch (error) {
            errorCall(error)
        }
    }
}

export const {setRestaurants, setDinners} = dashboardSlice.actions;
export const selectDashboard = state => state.dashboard;

export default dashboardSlice.reducer;
