import {Typography} from "@mui/material";
import logo from "../assets/images/sushi.png";
import React from "react";
import LongMenu from "./Menu";

export default function Headers(props) {
    return(
        <>
            <Typography variant="h4" component="div" gutterBottom>
                <img src={logo} style={{width: '100px'}} alt={'Sushi list'}/>
                <br/>
                {props.title}</Typography>
            {props.showMenu && <div style={{position:'absolute',right: '0px', top:'30px'}}><LongMenu /></div>}
        </>
    )
}
