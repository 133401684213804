import {Grid, Paper, Typography} from "@mui/material";
import {InlineShareButtons} from "sharethis-reactjs";
import React from "react";

export default function DinnerPaper(props) {

    let url = window.location.href.split("/")
    url = url[0] + "//" + url[2]

    return (
        <>
            <Grid item xs={12}>
                <Paper variant="outlined" style={{background: '#fdc'}}>
                    <Grid container spacing={2} style={{padding:'20px',paddingTop:'0px'}}>
                        <Grid item xs={12} >
                            <p>
                                <span>Ristorante: <strong>{props.dinner.restaurant}</strong></span><br/>
                                <span>Data: <strong>{props.dinner.created_at}</strong></span><br/>
                                <span>Creato da:  <strong>{props.dinner.user}</strong></span><br/>
                                <span> Codice: <strong>{props.dinner.code}</strong> </span>
                            </p>
                            {props.type === 'share' &&
                                <>
                                    <Typography variant="button" gutterBottom component="div">Condividi</Typography>
                                    <InlineShareButtons
                                        config={{
                                            alignment: 'left',
                                            color: 'social',
                                            enabled: true,
                                            font_size: 16,
                                            labels: null,
                                            language: 'it',
                                            networks: [
                                                'whatsapp',
                                                'telegram',
                                                'sms',
                                            ],
                                            padding: 12,
                                            radius: 4,
                                            show_total: false,
                                            size: 40,
                                            url: `${url}/?code=${props.dinner.code}`, // (defaults to current url)
                                        }}
                                    /><br/>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}