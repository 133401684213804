import {createSlice} from "@reduxjs/toolkit";
import {callGet, errorCall} from "../../utils/call";

export const resumeSlice = createSlice({
    name: 'resume',
    initialState: {
        orders: [],
        notes: ''
    },
    reducers: {
        setResume: (state, action) => {
            state.orders = action.payload.result
            state.notes = action.payload.note
        }
    },
})

export function getOrders() {
    return async dispatch => {
        try {
            const dinner = JSON.parse(localStorage.getItem('sl_dinner'))
            const response = await callGet(`order/result/${dinner.id}`, true)

            if (response.status === 200) {
                dispatch(setResume(response.data))
            }

        } catch (error) {
            errorCall(error)
        }
    }
}
export const {setResume} = resumeSlice.actions;
export const selectResume = state => state.resume;

export default resumeSlice.reducer;
