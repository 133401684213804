import { configureStore } from '@reduxjs/toolkit';
import orderReducer from './sections/order/OrderSlice';
import listReducer from './sections/list/ListSlice';
import resumeReducer from './sections/resume/ResumeSlice';
import detailReducer from './sections/detail/DetailSlice';
import dashboardReducer from './sections/dashboard/DashboardSlice';
import dinnerReducer from './sections/dinner/DinnerSlice'
export default configureStore({
  reducer: {
    order: orderReducer,
    list: listReducer,
    resume: resumeReducer,
    detail: detailReducer,
    dashboard: dashboardReducer,
    dinner: dinnerReducer
  },
});
