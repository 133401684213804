import React, {useEffect, useRef} from 'react';
import {
    Backdrop,
    Container, Divider,
    Grid, Typography
} from "@mui/material";
import toast, {Toaster} from "react-hot-toast";
import Headers from "../../components/Headers";
import { GoogleLogin } from '@react-oauth/google';
import {refreshTokenSetup} from "../../utils/refreshTokenGoogle";
import Login from "./components/Login";
import SignIn from "./components/SignIn";
import {callGet, callPost} from "../../utils/call";
import {isEmpty} from "lodash";
import Dashboard from "../dashboard/Dashboard";
import gsap from "gsap";
import {getBeta} from "../../utils/beta";
import jwt_decode from "jwt-decode";

export default function Home() {
    const [showSignIn, setShowSignIn] = React.useState(false);
    const [openBaufurto, setOpenBaufurto] = React.useState(false)
    const boxRef = useRef();
    const changeShowSignIn = (elm) => {
        setShowSignIn(elm)
    }
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get('code');

    const baufurto = () => {
        setOpenBaufurto(true)
        gsap.to(boxRef.current, {
            rotation: "+=360",
            opacity: 1,
            width: '300px',
            height: '300px',
            duration: 0.8
        });
        var AudioPlay = new Audio ('/baufurto.mp3');
        AudioPlay.play();
    }
    /* LOGIN GOOGLE */
    const onSuccess = async (res) => {
        var decoded = jwt_decode(res.credential);
        const bauFurto = getBeta('baudog')
        const response = await callPost(`auth/google/signin`, true, {
            email:decoded.email,
            givenName:decoded.given_name,
            googleId:decoded.sub
        })
        if(bauFurto) baufurto()
        toast.success(response.data.msg)
        localStorage.setItem('sl_id', response.data.data.id)
        localStorage.setItem('sl_name', response.data.data.name)
        localStorage.setItem('sl_isLogged', 1)
        localStorage.setItem('sl_google', 1)
        //refreshTokenSetup(res);
         setTimeout(() => {
             window.location.href = '/';
         }, (bauFurto)? 6000 : 1000)
    };
    const onFailure = (e) => {
        console.log(e)
        toast.error('L\'iscrizione con google non sta funzionando... iscriviti manualmente', {
            position: "bottom-center"
        })
    };
    const isNotLogged = isEmpty(localStorage.getItem('sl_isLogged'))

    useEffect(() => {
        if(!isEmpty(code)){
           localStorage.setItem('sl_dinnerCode',code)
        }
        const beta = async () => {
            try {
                const response = await callGet(`auth/beta/list`, false)
                localStorage.setItem('sl_beta',JSON.stringify(response.data.data))
            } catch (error) {

            }
        }
        beta()
        // eslint-disable-next-line
    }, [])

    return (<>
        <Toaster/>
        <Container maxWidth="sm">
            { !isNotLogged &&
                <>
                    <Dashboard />
                </>
            }
            { isNotLogged &&
                <>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign={'center'} style={{position: 'relative'}}>
                        <Headers title={'SUSHI LIST'} showMenu={false}/>
                    </Grid>
                    <Grid item xs={12} textAlign={'center'}>
                        <GoogleLogin
                            buttonText="ENTRA CON GOOGLE"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={'single_host_origin'}
                            style={{marginTop: '100px'}}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <Divider>Oppure inserisci</Divider>
                    </Grid>
                </Grid>
                {!showSignIn && <Login event={changeShowSignIn} bauEvent={baufurto}/>}
                {showSignIn && <SignIn event={changeShowSignIn} bauEvent={baufurto}/>}
                </>
            }

        </Container>
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={openBaufurto}
        >
            <div className="box" ref={boxRef} style={{opacity:0,width:0,height:0}}>
                <div style={{maxWidth: '300px', maxHeight: '300px'}}>
                    <img src='/corginatale.png' style={{maxWidth: '100%'}} alt={'bauuuguri'}/>
                    <Typography variant="h3" gutterBottom component="div" textAlign={'center'}>
                        B
                        <span style={{color:'#f00'}}>A</span>
                        U
                        <span style={{color:'#f00'}}>G</span>
                        U
                        <span style={{color:'#f00'}}>R</span>
                        I
                    </Typography>
                    <audio src={'/baufurto.mp3'} />
                </div>
            </div>
        </Backdrop>
    </>)
}
