import React, {useEffect} from 'react';
import {
    Avatar,
    Container,
    Grid, Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {Toaster} from "react-hot-toast";
import {useParams} from "react-router-dom";
import {getDetail, selectDetail} from "./DetailSlice";
import OrderList from "../dinner/components/OrderList";
import {isEmpty} from "lodash";
import LongMenu from "../../components/Menu";
import logo from "../../assets/images/sushi.png";


export default function Detail() {
    const state = useSelector(selectDetail);
    const dispatch = useDispatch();
    const id = useParams().id

    useEffect(() => {
        dispatch(getDetail(id))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const randomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    return (<>
        <Toaster/>
        <Container maxWidth="sm">
            <Grid container spacing={2}>
                <Grid item xs={12} style={{position: 'relative'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{textAlign:'center'}}>
                            <img src={logo} style={{width: '100px'}} alt={'Sushi list'}/>
                            <br/>
                        </Grid>
                        <Grid item xs={1}>
                            <Avatar style={{width: 45, height: 45, marginTop: '5px'}}
                                    src={(!isEmpty(state.image)) ? state.image : ''} sx={{bgcolor: randomColor()}}>
                                {(isEmpty(state.image)) ? state.name.substr(0, 1).toUpperCase() : ''}
                            </Avatar>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant="h4" component="div" style={{paddingTop: '10px', paddingLeft: '10px'}}>
                                {state.name}
                            </Typography>
                            <div style={{position:'absolute',right: '0px', top:'30px'}}><LongMenu /></div>
                        </Grid>
                    </Grid>
                </Grid>
                <>
                    <OrderList data={{order: state.orders, note: state.notes}} type={'all'}/>
                </>
            </Grid>
        </Container>

    </>)
}
