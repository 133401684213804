import axios from "axios";
import {isNumber,isEmpty} from "lodash";
import toast from "react-hot-toast";
export const callGet = (url,auth=false,params = null) =>{
    let config = {};
    if(auth) {
        config = {withCredentials: true}
    }
    config.validateStatus = (status) => {
        return status >= 200 && status <= 304;
    };
    if(params !== null)  config.params = params;
   return axios.get(`${process.env.REACT_APP_SERVER_URL}${(':'+process.env.REACT_APP_SERVER_PORT) || ''}/${process.env.REACT_APP_SERVER_PATH}${url}`, config );
}

export const callPost = (url, auth =false,params = {},contentType = 'application/json') => {
    let config = {};
    if(auth) {
        config = {withCredentials: true}
    }
    config.headers = {
        'Content-Type': contentType
    }
    config.validateStatus = (status) => {
        return status >= 200 && status <= 304;
    };

    return axios.post(`${process.env.REACT_APP_SERVER_URL}${(':'+process.env.REACT_APP_SERVER_PORT) || ''}/${process.env.REACT_APP_SERVER_PATH}${url}`,params, config );
}
export const apiUrl = (path) => {
    return `${process.env.REACT_APP_SERVER_URL}${(':'+process.env.REACT_APP_SERVER_PORT) || ''}${path}`
}
export const errorCall = (error) => {

    if(isNumber(error.response?.status)){
        if(error.response?.status === 401){
            toast.error('La sessione è scaduta')
            localStorage.clear()
            window.location.href='/'
        }else {
            if (!isEmpty(error.response.data.msg)) {
                toast.error(error.response.data.msg)
            }
        }
    }else {
        toast.error('Qualcosa è andato storto riprova più tardi')
        setTimeout(()=>{
            window.location.href='/'
        },1000)
    }
}