import React, {useEffect} from 'react';
import {
    Container,
    Grid
} from "@mui/material";
import { getOrders, selectResume} from "./ResumeSlice";
import {useDispatch, useSelector} from "react-redux";
import {Toaster} from "react-hot-toast";
import Headers from "../../components/Headers";
import {getDinner, selectDinner} from "../dinner/DinnerSlice";
import DinnerPaper from "../../components/DinnerPaper";
import OrderList from "../dinner/components/OrderList";


export default function Resume() {
    const state = useSelector(selectResume);
    const stateDinner = useSelector(selectDinner);
    const dispatch = useDispatch();
    const dinner = JSON.parse(localStorage.getItem('sl_dinner'))

    useEffect(() => {
        dispatch(getOrders(state))
        dispatch(getDinner())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <Toaster/>
        <Container maxWidth="sm">
            <Grid container spacing={2}>
                <Grid item xs={12} textAlign={'center'} style={{position:'relative'}}>
                    <Headers title={'Ordinazione'} showMenu={true}/>
                </Grid>
                {(state.orders.length === 0) && <Grid item xs={12} textAlign={'center'}>
                    <>Non ci sono ordini :'(</>
                </Grid> }
                {(state.orders.length > 0) && <>
                    <DinnerPaper dinner={{
                        restaurant: stateDinner.dinner.restaurant,
                        created_at: stateDinner.dinner.created_at,
                        user: stateDinner.dinner.user,
                        code: dinner.code
                    }}
                                 type={'detail'}
                    />

                    <OrderList data={{order: state.orders, note: state.notes}} type={'all'}/>
                </>
                }
            </Grid>
        </Container>

    </>)
}
