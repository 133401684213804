import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid
} from "@mui/material";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {checkIsValid, getDinner, getOrderByDinner, selectDinner} from "./DinnerSlice";
import {SentimentVeryDissatisfied} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import {isEmpty} from "lodash";
import OrderList from "./components/OrderList";
import {closeDinner} from "../dashboard/DashboardSlice";
import DinnerPaper from "../../components/DinnerPaper";

export default function Dinner() {
    const state = useSelector(selectDinner);
    const dinner = JSON.parse(localStorage.getItem('sl_dinner'))
    const dispatch = useDispatch();
    const history = useHistory();

    const [open, setOpen] = React.useState(false)
    const [openchange, setOpenChange] = React.useState(false)
    const [dinnerNewOld, setDinnerNewOld] = React.useState(null)

    const txtOwner = 'Se abbandonerai la cena questa verrà chiusa e nessuno potrà più aggiungere o modificare ordini.'
    const txtUser = "Se abbandoni la cena il tuo ordine NON verrà cancellato"
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(async () => {
        dispatch(getDinner())
        dispatch(getOrderByDinner())
        const check = await checkIsValid()
        if (check.status) {
            setOpenChange(true)
            setDinnerNewOld(check)
        }
        // eslint-disable-next-line
    }, [])


    return (<>
        <DinnerPaper dinner={{
            restaurant: state.dinner.restaurant,
            created_at: state.dinner.created_at,
            user: state.dinner.user,
            code: dinner.code
        }}
                     type={'share'}
        />
        {isEmpty(state.data._id) && state.loaded &&
            <Grid item xs={12}>
                <Button variant="contained" size="large" style={{width: '100%'}} onClick={() => {
                    history.push('/order')
                }}>
                    AGGIUNGI ORDINE
                </Button>

            </Grid>
        }
        {!isEmpty(state.data._id) && state.loaded &&
            <>
                <OrderList data={state.data} type={'single'}/>
            </>
        }
        <Grid item xs={12}>
            <Button variant="contained" size="large" style={{width: '100%'}} color="error"
                    startIcon={<SentimentVeryDissatisfied/>} onClick={() => {
                handleClickOpen()
            }}>ABBANDONA LA CENA </Button>
        </Grid>

        <Dialog
            open={open}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"ATTENZIONE!"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {(dinner.type === 'owner') ? txtOwner : txtUser}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button onClick={() => {
                    dispatch(closeDinner(dinner.id));
                    handleClose()
                }}>Conferma</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={openchange}
                keepMounted
                aria-describedby="alert-dialog-slide-description">
            <DialogTitle>{"ATTENZIONE!"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Sei già dentro <strong>{dinnerNewOld?.old?.code}</strong> ma hai cliccato il link per entrare
                    in <strong>{dinnerNewOld?.new?.code}</strong><br/>
                    cosa vuoi fare?
                </DialogContentText>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button variant="contained" size="large" style={{width: '100%'}} onClick={() => {
                                setOpenChange(false)
                                localStorage.removeItem('sl_dinnerCode')
                                window.location.href = '/'
                            }}>
                                RIMANI QUI
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" size="large" style={{width: '100%'}} color="success"
                                    onClick={() => {
                                        setOpenChange(false)
                                        dispatch(closeDinner(dinnerNewOld.old.id,false))
                                        localStorage.setItem('sl_dinner', JSON.stringify(dinnerNewOld?.new))
                                        window.location.href = '/'

                                    }}>ENTRA IN {dinnerNewOld?.new?.code} (creato
                                da {dinnerNewOld?.new?.user}) </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </DialogContent>
        </Dialog>

    </>)
}
