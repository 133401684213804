import {createSlice} from "@reduxjs/toolkit";
import {callGet, errorCall} from "../../utils/call";
import {isEmpty} from "lodash";
import toast from "react-hot-toast";
import {closeDinner} from "../dashboard/DashboardSlice";
export const dinnerSlice = createSlice({
    name: 'dashboard',
    initialState: {
        dinner: {},
        data: {name:'', note:'',order:[]},
        loaded: false
    },
    reducers: {
        setDinner: (state, action) => {
            state.dinner = action.payload
        },
        setData: (state, action) => {
            state.data = action.payload
            state.loaded = true
        }
    },
})

export function getDinner() {
    const dinner = JSON.parse(localStorage.getItem('sl_dinner'))
    const codeVal = dinner?.code
    return async dispatch => {
        try {
            if(isEmpty(codeVal)){
                window.location.href='/'
            }
            const response = await getDinnerData(codeVal)

            if (response.status === 200) {
                if(response.data.active) {
                    dispatch(setDinner(response.data))
                }else{
                    dispatch(closeDinner(response.data.id))
                    toast.error('La cena è conclusa :(');

                    setTimeout(()=>{
                        window.location.href='/'
                    },1000)

                }
            }
        } catch (error) {
            errorCall(error)
        }
    }
}
export async function getDinnerData(code) {
    const dinner = JSON.parse(localStorage.getItem('sl_dinner'))
    const codeVal = (code !== null) ? code : dinner?.code
    const response = await callGet(`dinner/${codeVal}`, true)
    return response
}
export function getOrderByDinner(){
    const dinner = JSON.parse(localStorage.getItem('sl_dinner'))
    return async dispatch => {
        try {
            //const beta = !isEmpty(localStorage.getItem('sl_pietanze_name'))? '?beta=true' : ''
            const beta = ''
            const response = await callGet(`order/dinner/${dinner.id}${beta}`, true)

            if (response.status === 200) {
                let dinner = JSON.parse(localStorage.getItem('sl_dinner'))
                dinner= {...dinner, order:response.data._id}

                localStorage.setItem('sl_dinner',JSON.stringify({...dinner, order:(isEmpty(response.data._id))? null : response.data._id }))
                dispatch(setData(response.data))

            }
        } catch (error) {
            errorCall(error)
        }
    }
}
export async function checkIsValid() {
    const dinner = JSON.parse(localStorage.getItem('sl_dinner'))
    const dinnerCode = localStorage.getItem('sl_dinnerCode')
    if (dinnerCode !== dinner.code && !isEmpty(dinnerCode)) {
        const dataNewDinner = await getDinnerData(dinnerCode)
        return {status: true, new: dataNewDinner?.data,old:dinner}
    }else{
        return {status: false}
    }
}
export const {setDinner,setData} = dinnerSlice.actions;
export const selectDinner = state => state.dinner;

export default dinnerSlice.reducer;
