import {createSlice} from "@reduxjs/toolkit";
import {callGet, errorCall} from "../../utils/call";
import toast from 'react-hot-toast';

export const listSlice = createSlice({
    name: 'List',
    initialState: {
        orders: []
    },
    reducers: {
        setOrder: (state, action) => {
            state.orders = action.payload
        }
    },
})

export function getOrders() {
    return async dispatch => {
        try {
            const dinner = JSON.parse(localStorage.getItem('sl_dinner'))
            const response = await callGet(`order/list/${dinner.id}`, true)

            if (response.status === 200) {
                dispatch(setOrder(response.data))
            }

        } catch (error) {
            errorCall(error)
        }
    }
}

export async function deleteOrder(id) {
    const response = await callGet(`order/delete/${id}`, true)
    if (response.status === 200) {
        toast.success('Eliminato correttamente', {
            position: "bottom-center"
        })
        return true
    }else{
        toast.success('Qualcosa è andato storto', {
            position: "bottom-center"
        })
        return false
    }

}

export const {setOrder} = listSlice.actions;
export const selectList = state => state.list;

export default listSlice.reducer;
