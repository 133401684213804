import React, {useEffect} from 'react';
import {
    Avatar,
    Container, Divider,
    Grid,
    Typography
} from "@mui/material";
import {deleteOrder, getOrders, selectList} from "./ListSlice";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid4} from "uuid";
import DeleteForever from "@mui/icons-material/DeleteForever";
import {Toaster} from "react-hot-toast";
import {useHistory} from "react-router-dom";
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import Headers from "../../components/Headers";
import {apiUrl} from "../../utils/call";
import {isEmpty} from "lodash";

export default function List() {
    const state = useSelector(selectList);
    const dispatch = useDispatch();
    const history = useHistory();
    const dinner = JSON.parse(localStorage.getItem('sl_dinner'))

    const randomColor = () => {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
    }
    useEffect(() => {
        dispatch(getOrders(state))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (<>
        <Toaster/>
        <Container maxWidth="sm">
            <Grid container spacing={2}>
                <Grid item xs={12} textAlign={'center'} style={{position:'relative'}}>
                    <Headers title={'Ordini creati'} showMenu={true}/>
                </Grid>
                {(state.orders.length === 0) && <Grid item xs={12} textAlign={'center'}>
                    <>Non ci sono ordini :'(</>
                </Grid> }
                {
                    state.orders.map((elm, k) => {
                        let sizeName = 5
                        if(dinner.type !== 'owner'){
                            sizeName = 7
                        }

                        return (<React.Fragment key={uuid4()}>
                            <Grid item xs={2} >
                                <Avatar style={{width:45, height:45, marginTop: '5px'}}  src={ (!isEmpty(elm.user.image) )? apiUrl(elm.user.image) : ''}  sx={{ bgcolor: randomColor()}}>
                                    {(isEmpty(elm.user.image) )? elm.name.substr(0,1).toUpperCase() : ''}
                                </Avatar>
                            </Grid>
                            <Grid item xs={sizeName}>
                                <Typography variant="h6" component="div" style={{paddingTop:'10px', paddingLeft:'10px'}}>{elm.name}</Typography>

                            </Grid>
                            <Grid item xs={2} textAlign={'right'}>
                                <FormatListBulleted style={{fontSize: '30px', marginTop: '10px', cursor:'pointer'}} onClick={async () => {
                                    if(localStorage.getItem('sl_id') === elm.user.id) {
                                        history.push('/')
                                    }else{
                                        history.push('/details/' + elm._id)
                                    }
                                }}  />
                            </Grid>

                            {dinner.type === 'owner' && <Grid item xs={2} textAlign={'right'}>
                                <DeleteForever style={{fontSize: '30px', marginTop: '10px', cursor:'pointer'}} onClick={async () => {
                                   const response = await deleteOrder(elm._id)
                                    if(response) dispatch(getOrders(state))
                                }} />
                            </Grid>}
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                        </React.Fragment>)
                    })}
            </Grid>
        </Container>

    </>)
}
