import { Divider, Grid} from "@mui/material";
import Headers from "../../components/Headers";
import React, {useEffect} from "react";
import Create from "./components/create";
import {getDinner, selectDashboard} from "./DashboardSlice";
import {useDispatch, useSelector} from "react-redux";
import DinnerSignIn from "./components/signin";
import Dinner from "../dinner/Dinner";
import {isEmpty} from "lodash";

export default function Dashboard() {
    const state = useSelector(selectDashboard);
    const dispatch = useDispatch();
    const dinner = JSON.parse(localStorage.getItem('sl_dinner'))
    useEffect(() => {
        dispatch(getDinner())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (<>
        <Grid container spacing={2}>
            <Grid item xs={12} textAlign={'center'} style={{position: 'relative'}}>
                <Headers title={(!isEmpty(dinner))? dinner.name : 'Welcome!'} showMenu={true}/>
            </Grid>
            {state.dinners.length !== 0 && state.loaded && <Dinner/>}
            {!state.dinners.length && state.loaded &&
                <>
                    <DinnerSignIn />
                    <Grid item xs={12}>
                        <br/>
                        <Divider>Oppure </Divider>
                    </Grid>
                    <Create/>
                </>
            }

        </Grid>
    </>)
}
