import {Button, Grid, TextField, Typography} from "@mui/material";
import {Send} from "@mui/icons-material";
import React, {useEffect} from "react";
import {callGet, errorCall} from "../../../utils/call";
import toast from "react-hot-toast";
import {isEmpty} from "lodash";
import {getDinner} from "../DashboardSlice";
import {useDispatch} from "react-redux";

export default function DinnerSignIn() {
    const [enterDinner, setEnterDinner] = React.useState('');
    const dispatch = useDispatch();

    const submitDinner = (code = null) => {
        return async dispatch => {
            try {
                // eslint-disable-next-lin
                if (isEmpty(enterDinner) && code === null) throw {response: {data: {msg: 'Il codice non può essere vuoto'},status:500}}
                const response = await callGet(`dinner/signin/${(code !== null)? code.toLowerCase().trim() : enterDinner.toLowerCase().trim()}`, true)
                toast.success(response.data.msg)
                localStorage.setItem('sl_dinner', JSON.stringify({
                    code: response.data.data.code,
                    id: response.data.data._id,
                    name: response.data.data.name,
                    order: null
                }))
                dispatch(getDinner())

            } catch (error) {
                errorCall(error)
            }
        }
    }
    useEffect(() => {
        if(!isEmpty(localStorage.getItem('sl_dinnerCode'))){
            dispatch(submitDinner(localStorage.getItem('sl_dinnerCode')))
            localStorage.removeItem('sl_dinnerCode')
        }

        // eslint-disable-next-line
    }, [])
    return (<>
        <Grid item xs={12}>
            <Typography variant="button" gutterBottom component="div">Partecipa ad una cena</Typography>
        </Grid>
        <Grid item xs={8} textAlign={'center'}>
            <TextField
                required
                id="codice"
                label="Codice"
                name={'codice'}
                style={{background: '#fff', width: '100%'}}
                onBlur={(e) => {
                        setEnterDinner(e.target.value)
                }}
            />
        </Grid>
        <Grid item xs={4}>
            <Button variant="contained" endIcon={<Send/>} style={{marginTop: '10px'}} onClick={() => {dispatch(submitDinner())}}>
                Entra
            </Button>
        </Grid>
    </>)
}
